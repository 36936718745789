import './App.css';
import React from 'react';
 import ReactGA from 'react-ga';


function App() {
   
    const TRACKING_ID = "G-9HTSNLH7B7";
    ReactGA.initialize(TRACKING_ID);


  const NavigationManager = (url) => { window.location.href = url; };

  const downloadCard = () => {
    NavigationManager("cartao.vcf");
  };

  const enviarEmail = () => {
    NavigationManager("mailto:motta@menphis.com.br");
  }; 

  const openChampions = () => {
    window.location.href = 'https://www.progress.com/champions';
  }  
  
  return ( 

    <div class="App">
     
    <img onClick={openChampions} className='badge' title='Badge Progress Telerik Chamption' alt='Badge' src='./resources/img/badge.png'></img>
 
    <div class="bodyIndex">


        <div class="mainBox">

               <center><a title='Visit my digital card in English' class='country' href='https://www.jsmotta.com'><img alt='USA' src='./resources/img/flag_usa_24.png' /></a>
                       <a title='Visite meu cartão digital em Português do Brasil' class='country' href='https://www.jsmotta.com.br'><img alt='Brazil' src='./resources/img/flag_brazil_24.png' /></a></center>
                      
            <div class="mainPic">
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />  
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
            
            <div class="boxAdv3">
                <div class="boxNome">
                    <br />
                    <span class='meuNome'>Jefferson S. Motta</span>
                    <br />
                </div>
            </div>                    
            <span class='myAttributes'><br />Telerik Consultant<br />Tech Lead<br />Senior C# .NET Fullstack Developer<br />Systems Analyst<br /></span>
            <div class="clear"></div>
            
            <br />
            <div class="buttons">                
                <button title="Enviar e-mail" onClick={ () => enviarEmail()}>
                    <img width="51" height="51" src="resources/img/icons/outlookMail.png" alt="E-mail" />
                </button>               
            </div>
            <div class="clear"></div>   
            <br />      
            <br /> 
            <div class="links">
                <a target="champion" href="https://www.progress.com/champions">Progress Telerik Champion</a>
            </div>
            <div class="links">
                <a target="telerik" href="https://www.telerik.com/blogs/author/jefferson-s.-motta">telerik.com/blog</a>
            </div>
            <div class="links">
                <a target="cscorner" href="https://www.c-sharpcorner.com/members/jefferson-s.-motta">c-sharpcorner.com</a>
            </div>
            <div class="links">
                <a target="linkedin" href="https://br.linkedin.com/in/jefferson-s-motta-lnk">linkedin.com</a>
            </div> 
            <div class="links">
                <a target="advnetdemo" href="https://demo.advocati.net/Login.aspx?auto=sim">demo.advocati.net</a>
            </div>
            <div class="links">
                <a target="menphis" href="https://menphis.com.br">menphis.com.br</a>
            </div> 
            <div class="links">
                <a rel="medium" target='mast' href="https://medium.com/@jsmotta3000">medium</a>
            </div>            
            <div class="links">
                <a rel="me" target='mast' href="https://me.dm/@jefferson8motta">mastodon</a>
            </div> 
            <div class="links">
                <a target='github' href="https://github.com/jssmotta">GitHub</a>
            </div> 
                    
            </div>
            

        </div>
      </div>
  );


  }
 

export default App;
